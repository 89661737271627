import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';

import { computeColProps } from './LongTextService';

import RichText from '~/shared/components/RichText';
import * as full from '~/shared/components/RichText/configs/full';
import dataSelector from '~/shared/util/data-selector';
import * as Url from '~/shared/services/url';
import { FlattenedLongText } from '~/shared/services/contentful';

const StyledCol = styled(Col)`
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

/**
 * A rich text section.
 */
function LongText({
  content,
  className = '',
  contentType,
  name,
  width = 'Page',
  id,
}: Partial<FlattenedLongText> & { className?: string }) {
  const isStorylaneContent = useMemo(() => {
    const allowedHosts = ['app.storylane.io'];
    if (!content?.content?.length) {
      return false;
    }

    return content?.content?.some(
      (entry) =>
        entry?.nodeType === 'embedded-entry-block' &&
        allowedHosts.includes(Url.parse(entry?.data?.target?.src).host),
    );
  }, [content]);

  if (isEmpty(content)) {
    return null;
  }

  return (
    <Grid
      className={className}
      data-selector={dataSelector('section', 'long_text')}
      data-elbcontext="component:long_text"
      role="region"
    >
      <Row>
        <StyledCol {...computeColProps({ isStorylaneContent, width })}>
          <RichText
            richText={content}
            renderNode={full.createRenderNode({
              contentType,
              contentEntryName: name,
              contentEntryId: id,
            })}
            renderMark={full.renderMark}
          />
        </StyledCol>
      </Row>
    </Grid>
  );
}

/**
 * @component
 */
export default LongText;
