import { ColProps } from '@sumup/circuit-ui/legacy';

import { FlattenedLongText } from '~/shared/services/contentful';
import {
  pageFullWidthColProps,
  pageWidthColProps,
  storylanePageWidthColProps,
} from '~/shared/styles/helpers';

export function computeColProps(opts: {
  isStorylaneContent: boolean;
  width: FlattenedLongText['width'];
}): ColProps {
  if (opts.isStorylaneContent) {
    return storylanePageWidthColProps;
  }

  if (opts.width === 'Full') {
    return pageFullWidthColProps;
  }

  return pageWidthColProps;
}
